import { useEffect, useRef } from "react";
import { CLOSE_ICON } from "../../assets/utils/icons";
import "./Popup.css";

function Popup({ popup, isOpen, title, closePopup, children, itemName }) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closePopup(popup);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div
      className={`popup ${isOpen ? "popup_opened" : ""}`}
      ref={overlay}
      onTouchStart={() => closePopup(popup)}
    >
      <div className="popup__container">
        <button
          className="popup__close-button"
          type="button"
          aria-label="Закрыть попап"
          onClick={() => closePopup(popup)}
        >
          {CLOSE_ICON}
        </button>
        <p className="popup__title">{title}</p>
        {itemName ? <p className="popup__item-name">{itemName}?</p> : <></>}
        {children}
      </div>
    </div>
  );
}

export default Popup;
