import { MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${this._BASE_URL}/users/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  login({ userId }) {
    return fetch(`${this._BASE_URL}/users/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_code: userId,
      }),
    }).then(this._checkResponse);
  }

  logout() {
    return fetch(`${this._BASE_URL}/users/logout`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getMe() {
    return fetch(`${MAIN_URL}/users/get-me`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMe,
        params: {},
      })
    );
  }

  getCategories({ last_id, limit }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/items/categories?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCategories,
        params: { last_id, limit },
      })
    );
  }

  getSubcategories({ last_id, limit, category_id }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;
    if (category_id !== undefined) params.category_id = category_id;

    return fetch(
      `${MAIN_URL}/items/subcategories?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSubcategories,
        params: { last_id, limit, category_id },
      })
    );
  }

  getCurrentItem() {
    return fetch(`${MAIN_URL}/items/current`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCurrentItem,
        params: {},
      })
    );
  }

  getNewItem() {
    return fetch(`${this._BASE_URL}/items/new`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getNewItem,
        params: {},
      })
    );
  }

  addNewCategory({ newCategory }) {
    return fetch(`${this._BASE_URL}/items/add-new-category`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: newCategory,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.addNewCategory,
        params: { newCategory },
      })
    );
  }

  addNewSubcategory({ category, newSubcategory }) {
    return fetch(`${this._BASE_URL}/items/add-new-sub-category`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: newSubcategory,
        category_id: category,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.addNewSubcategory,
        params: { category, newSubcategory },
      })
    );
  }

  prolongTimeout({ id }) {
    return fetch(`${this._BASE_URL}/items/prolong-timeout`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.prolongTimeout,
        params: { id },
      })
    );
  }

  skipItem({ id, reason }) {
    return fetch(`${this._BASE_URL}/items-writers/skip-and-save-reason`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: id,
        reason,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.skipItem,
        params: { id, reason },
      })
    );
  }

  async createItem({ data }) {
    return fetch(`${this._BASE_URL}/items-writers/write-new-data`, {
      method: "POST",
      credentials: "include",
      body: data,
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createItem,
        params: { data },
      })
    );
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
