import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import mainApi from "../../assets/api/MainApi";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Auth from "../Auth/Auth";
import "./App.css";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    mainApi
      .getMe()
      .then((res) => {
        console.log(res);
        setLoggedIn(true);
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
        setLoggedIn(false);
      });

   
  }, []);



  return (
    <div className="App">
      {isLoggedIn ? <Header {...{ user }} /> : null}
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route
              path="/auth"
              element={<Auth {...{ setLoggedIn, setUser }} />}
            />
            <Route path="*" element={<Navigate to="/auth" replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Main {...{ user, setUser }} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
