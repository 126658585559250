import { useState } from "react";
import { Link } from "react-router-dom";
import {
  USER_ICON,
  CLOSE_ICON,
  WRAPPED_MENU_ICON,
} from "../../assets/utils/icons";
import "./Header.css";

function Header({ user }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header__content-box">
        <Link to="/" className="header__logo">
          div-base
        </Link>

        <div
          className={`header__nav-box ${
            isOpen ? "header__nav-box_menu-open" : ""
          }`}
        >
          <div className="header__nav-wrapper">
            <div className="header__nav-wrapper-box">
              <div className="header__icons-box">
                <Link
                  to="/"
                  className="header__logo"
                  onClick={isOpen ? toggleMenu : undefined}
                >
                  div-base
                </Link>
                <button
                  className="header__close-button"
                  type="button"
                  aria-label="Close navigation"
                  onClick={toggleMenu}
                >
                  {CLOSE_ICON}
                </button>
              </div>

              <ul className="header__info-list">
                <li className="header__item">
                  <p className="header__info-text">Выполнено: {user.done}</p>
                </li>
                <li className="header__item">
                  <p className="header__info-text">
                  Пропущено: {user.skipped}
                  </p>
                </li>
                <li className="header__item">
                  <div className="header__user-icon-box">{USER_ICON}</div>
                  <p className="header__info-text">{user._id}</p>
                </li>
              </ul>
            </div>
          </div>

          <button
            className="header__open-button"
            type="button"
            aria-label="Open navigation"
            onClick={toggleMenu}
          >
            {WRAPPED_MENU_ICON}
          </button>
          <div className="header__background" />
        </div>
      </div>
    </header>
  );
}

export default Header;
