import { useState, useEffect, useRef } from "react";
import { Field, ErrorMessage } from "formik";
import { SELECT_ARROW_ICON } from "../../assets/utils/icons";
import "./SelectField.css";

function SelectField({
  userType,
  name,
  label,
  value,
  placeholder,
  touched,
  error,
  list,
  setFieldValue,
  getSubcategories,
  handleSelectCategory = null,
  setSelectedCategory = null
}) {
  const overlay = useRef();
  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div className="select">
      {label ? (
        <label
          className={`select__label ${userType === "writer" ? "select__label_type_writer" : ""
            }`}
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}

      <div
        className="select__input-box"
        onClick={() => (userType === "writer" ? setPopupOpen(true) : undefined)}
      >
        <Field
          className={`select__input ${touched && Boolean(error) ? "select__input_error" : ""
            } ${touched && !Boolean(error) && Boolean(value)
              ? "select__input_valid"
              : ""
            } ${userType === "reviewer" ? "select__input_type_reviewer" : ""}`}
          id={name}
          name={name}
          type="text"
          placeholder={placeholder}
          value={value || ""}
          readOnly
        />
        <div
          className={`select__arrow-icon ${isPopupOpen ? "select__arrow-icon_reverse" : ""
            }`}
        >
          {SELECT_ARROW_ICON}
        </div>
      </div>

      <ErrorMessage
        component="span"
        className="select__error-message"
        name={name}
      />

      <div
        className={`select__popup select__popup_type_${name} ${isPopupOpen ? "select__popup_opened" : ""
          }`}
      >
        <ul className="select__items-list">
          {list.map((item) => (
            <li
              className={`select__item ${item.text === value ? "select__item_selected" : ""
                }`}
              key={`${name + label}${item._id}${item.text}`}
              onClick={() => {
                console.log(item)
                if (handleSelectCategory) {
                  handleSelectCategory(item, setFieldValue)
                }
                setFieldValue(name, item.text);
                setPopupOpen(false);
                if (name === "category") {
                  getSubcategories(item._id);
                }
              }}
            >
              <p className="select__item-text">{item.text}</p>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="select__overlay"
        ref={overlay}
        onTouchStart={() => setPopupOpen(false)}
      />
    </div>
  );
}

export default SelectField;
