import { CHECKED_CHECKBOX_ICON } from "../../assets/utils/icons";
import "./Checkbox.css";

function Checkbox({ name, label, isChecked, disabled, setFieldValue, type }) {
  return (
    <div className="checkbox">
      <span
        className={`checkbox__label-text ${
          type ? `checkbox__label-text_type_${type}` : ""
        }`}
      >
        {label}
      </span>
      <label className="checkbox__label" htmlFor={name}>
        <input
          className="checkbox__invisible-input"
          id={name}
          name={name}
          type="checkbox"
          value={isChecked}
          onChange={() => setFieldValue(name, !isChecked)}
          checked={isChecked}
          disabled={disabled}
        />
        <span
          className={`checkbox__pseudo-item ${
            isChecked ? "checkbox__pseudo-item_checked" : ""
          } ${disabled ? "checkbox__pseudo-item_disabled" : ""} ${
            type ? `checkbox__pseudo-item_type_${type}` : ""
          }`}
        >
          {CHECKED_CHECKBOX_ICON}
        </span>
      </label>
    </div>
  );
}

export default Checkbox;
