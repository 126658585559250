import { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useIdleTimer } from "react-idle-timer";
import mainApi from "../../assets/api/MainApi";
import SelectField from "../SelectField/SelectField";
import Checkbox from "../Checkbox/Checkbox";
import Popup from "../Popup/Popup";
import {
  FIELD_REQUIRED_ERROR,
  PHOTO_REQUIRED_ERROR,
  PHOTO_TOO_BIG_ERROR,
  PHOTO_WRONG_TYPE_ERROR,
  PHOTO_MAX_ERROR,
  ROOM_LIST,
  REASONS_LIST,
  NOT_FOUND_REASON,
  WRONG_NAME_REASON,
  EXEPTIONS_ARRAY,
} from "../../assets/utils/constants";
import {
  ADD_ITEM_ICON,
  COPY_ICON,
  REMOVE_BUTTON_ICON,
} from "../../assets/utils/icons";
import "./Main.css";
import MiniPreloader from "../MiniPreloader/MiniPreloader";


let initialValuesWriter = {
  category: "",
  subcategory: "",
  room: "",
  noPhoto: false,
  photo: [],
  photoURL: [],
  noDescription: false,
  description: "",
  source: "",
  noHeight: false,
  height: "",
  noWidth: false,
  width: "",
  noDepth: false,
  depth: "",
  noWeight: false,
  weight: "",
  noColor: false,
  color: "",
  noMaterial: false,
  material: "",
  extraCharacteristics: [],
};

const initialValuesReviewer = {
  categoryCorrect: false,
  categoryUncorrect: false,
  subcategoryCorrect: false,
  subcategoryUncorrect: false,
  roomCorrect: false,
  roomUncorrect: false,
  photoCorrect: false,
  photoUncorrect: false,
  descriptionCorrect: false,
  descriptionUncorrect: false,
  heightCorrect: false,
  heightUncorrect: false,
  widthCorrect: false,
  widthUncorrect: false,
  depthCorrect: false,
  depthUncorrect: false,
  weightCorrect: false,
  weightUncorrect: false,
  colorCorrect: false,
  colorUncorrect: false,
  materialCorrect: false,
  materialUncorrect: false,
  extraCharacteristicsCorrect: false,
  extraCharacteristicsUncorrect: false,
};

const ProductSchema = Yup.object({
  category: Yup.string().required(FIELD_REQUIRED_ERROR),
  subcategory: Yup.string().required(FIELD_REQUIRED_ERROR),
  room: Yup.string().required(FIELD_REQUIRED_ERROR),
  noPhoto: Yup.boolean(),
  photo: Yup.array()
    .nullable()
    .max(10, PHOTO_MAX_ERROR)
    .test("is-correct-file", PHOTO_TOO_BIG_ERROR, checkIfFilesAreTooBig)
    .test("is-big-file", PHOTO_WRONG_TYPE_ERROR, checkIfFilesAreCorrectType)
    .when("noPhoto", {
      is: false,
      then: Yup.array().min(1, PHOTO_REQUIRED_ERROR),
    }),
  photoURL: Yup.array(),
  noDescription: Yup.boolean(),
  description: Yup.string().when("noDescription", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  source: Yup.string().required(FIELD_REQUIRED_ERROR),
  pasteText: Yup.string(),
  noHeight: Yup.boolean(),
  height: Yup.string().when("noHeight", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  noWidth: Yup.boolean(),
  width: Yup.string().when("noWidth", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  noDepth: false,
  depth: Yup.string().when("noDepth", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  noWeight: false,
  weight: Yup.string().when("noWeight", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  noColor: false,
  color: Yup.string().when("noColor", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  noMaterial: false,
  material: Yup.string().when("noMaterial", {
    is: false,
    then: Yup.string().required(FIELD_REQUIRED_ERROR),
  }),
  extraCharacteristics: Yup.array(),
});

const ReviewSchema = Yup.object({
  categoryCorrect: Yup.boolean().when("categoryUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  categoryUncorrect: Yup.boolean(),
  subcategoryCorrect: Yup.boolean().when("subcategoryUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  subcategoryUncorrect: Yup.boolean(),
  roomCorrect: Yup.boolean().when("roomUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  roomUncorrect: Yup.boolean(),
  photoCorrect: Yup.boolean().when("photoUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  photoUncorrect: Yup.boolean(),
  descriptionCorrect: Yup.boolean().when("descriptionUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  descriptionUncorrect: Yup.boolean(),
  heightCorrect: Yup.boolean().when("heightUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  heightUncorrect: Yup.boolean(),
  widthCorrect: Yup.boolean().when("widthUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  widthUncorrect: Yup.boolean(),
  depthCorrect: Yup.boolean().when("depthUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  depthUncorrect: Yup.boolean(),
  weightCorrect: Yup.boolean().when("weightUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  weightUncorrect: Yup.boolean(),
  colorCorrect: Yup.boolean().when("colorUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  colorUncorrect: Yup.boolean(),
  materialCorrect: Yup.boolean().when("materialUncorrect", {
    is: false,
    then: Yup.boolean().oneOf([true]),
  }),
  materialUncorrect: Yup.boolean(),
  extraCharacteristicsCorrect: Yup.boolean().when(
    "extraCharacteristicsUncorrect",
    {
      is: false,
      then: Yup.boolean().oneOf([true]),
    }
  ),
  extraCharacteristicsUncorrect: Yup.boolean(),
});

function checkIfFilesAreTooBig(files) {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
}

function checkIfFilesAreCorrectType(files) {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      if (
        !["image/jpg", "image/jpeg", "image/webp"].includes(
          file.type
        )
      ) {
        valid = false;
      }
    });
  }
  return valid;
}

function Main({ user, setUser }) {
  const [item, setItem] = useState({});
  const [emptyBlocks, setEmptyBlocks] = useState(Array(9).fill(" "));
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState({
    newCategory: false,
    newSubcategory: false,
    skipItem: false,
    leaveSession: false,
    report: false,
    skipReviewItem: false,
  });

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const [isSubmitPreloaderVisible, setSubmitPreloaderVisible] = useState(false)

  const [defaultSizes, setDefaultSizes] = useState({})

  useEffect(() => {
    mainApi
      .getCurrentItem()
      .then((res) => {
        console.log("current:", res);
        setItem(res);
        let sizes = res.name.match(/[[0-9]*[xXх/*][0-9]*]*/gi)
        if (sizes.length > 0) {
          sizes = sizes[0]
          if (sizes) {
            console.log(sizes)
            sizes = sizes.slice()
            let sizesArray = sizes.match(/[0-9]*/gi).filter((e) => Boolean(e)).map((e) => Number(e))
            if (sizesArray.length === 2) {
              if (sizesArray[0] >= sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[0],
                  depth: sizesArray[1]
                })
              }
              else if (sizesArray[0] < sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[1],
                  depth: sizesArray[0]
                })
              }
            } else {
              if (sizesArray[0] >= sizesArray[1] && sizesArray[0] >= sizesArray[2]) {
                setDefaultSizes({
                  width: sizesArray[0],
                })
              }
              else if (sizesArray[1] >= sizesArray[0] && sizesArray[1] >= sizesArray[2]) {
                setDefaultSizes({
                  width: sizesArray[1],
                })
              } else if (sizesArray[2] >= sizesArray[0] && sizesArray[2] >= sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[2],
                })
              }
            }
            console.log(sizesArray)
          }
        }

        // searchApi.getSearchResults({ text: res.name })
        //   .then((search_res) => {
        //     console.log(search_res)
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
      })
      .catch((err) => {
        console.log("currentErr:", err);
        switch (err.statusCode) {
          case 400:
            getNewItem();
            break;
          default:
            break;
        }
      });

    getCategories();
  }, []);

  function getCategories() {
    mainApi
      .getCategories({ limit: 25 })
      .then((res) => {
        console.log("categories:", res);
        setCategories(res.data);
      })
      .catch((err) => console.log("categoriesErr:", err));
  }

  function getSubcategories(id) {
    mainApi
      .getSubcategories({ limit: 25, category_id: id })
      .then((res) => {
        console.log("subcategories:", res);
        setSubcategories(res.data);
      })
      .catch((err) => console.log("subcategoriesErr:", err));
  }

  function getNewItem() {
    mainApi
      .getNewItem()
      .then((res) => {
        console.log("new:", res);
        setItem(res);
        let sizes = res.name.match(/[[0-9]*[xXх/*][0-9]*]*/gi)
        if (sizes.length > 0) {
          sizes = sizes[0]
          if (sizes) {
            console.log(sizes)
            sizes = sizes.slice()
            let sizesArray = sizes.match(/[0-9]*/gi).filter((e) => Boolean(e)).map((e) => Number(e))
            if (sizesArray.length === 2) {
              if (sizesArray[0] >= sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[0],
                  depth: sizesArray[1]
                })
              }
              else if (sizesArray[0] < sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[1],
                  depth: sizesArray[0]
                })
              }
            } else {
              if (sizesArray[0] >= sizesArray[1] && sizesArray[0] >= sizesArray[2]) {
                setDefaultSizes({
                  width: sizesArray[0],
                })
              }
              else if (sizesArray[1] >= sizesArray[0] && sizesArray[1] >= sizesArray[2]) {
                setDefaultSizes({
                  width: sizesArray[1],
                })
              } else if (sizesArray[2] >= sizesArray[0] && sizesArray[2] >= sizesArray[1]) {
                setDefaultSizes({
                  width: sizesArray[2],
                })
              }
            }
            console.log(sizesArray)
          }
        }
      })
      .catch((err) => console.log("newErr:", err));
  }

  function skipItem(reason) {
    mainApi
      .skipItem({ id: item._id, reason: reason })
      .then((res) => {
        console.log("skip:", res);
        getNewItem();
        setUser((prewItem) => ({
          ...prewItem,
          skipped: (Number(prewItem.skipped) + 1).toString(),
        }));
      })
      .catch((err) => {
        console.log("skipErr:", err);
        switch (err.statusCode) {
          case 400:
            getNewItem();
            break;
          default:
            break;
        }
      });
  }

  function handlePhotoChange(evt, setFieldValue, values) {
    const files = Array.from(evt.target.files);
    const newFiles = [...values.photo, ...files];
    setFieldValue(
      "photo",
      newFiles.length > 10 ? newFiles.slice(0, 10) : newFiles
    );

    let photos = [];
    files.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        photos.push(reader.result);
        let newPhotos = [...values.photoURL, ...photos];
        setFieldValue(
          "photoURL",
          newPhotos.length > 10 ? newPhotos.slice(0, 10) : newPhotos
        );
      };
    });

    setEmptyBlocks(
      Array(10 - newFiles.length - 1 < 0 ? 0 : 10 - newFiles.length - 1).fill(
        " "
      )
    );
  }

  function handleRemovePhoto(index, setFieldValue, values) {
    const photos = values.photo.slice();
    const photosURL = values.photoURL.slice();
    const newPhotos = photos.filter((item, i) => i !== index);
    const newPhotosURL = photosURL.filter((item, i) => i !== index);
    setFieldValue("photo", newPhotos);
    setFieldValue("photoURL", newPhotosURL);
    setEmptyBlocks(Array(10 - newPhotos.length - 1).fill(" "));
  }

  function handleRemoveExtra(index, setFieldValue, values) {
    const extras = values.extraCharacteristics.slice();
    const newExtras = extras.filter((item, i) => i !== index);
    setFieldValue("extraCharacteristics", newExtras);
  }

  function openPopup(popup) {
    setPopupOpen({ ...isPopupOpen, [popup]: true });
  }

  function closePopup(popup) {
    setPopupOpen({ ...isPopupOpen, [popup]: false });
  }

  function loadNewItem() {
    const reason = {
      not_found: false,
      wrong_name_or_production: false,
      skipped: true,
      other_reason: null,
    };

    skipItem(reason);
    closePopup("leaveSession");
  }

  function handleContinue() {
    mainApi
      .prolongTimeout({ id: item._id })
      .then((res) => console.log("prolong timeout:", res))
      .catch((err) => {
        console.log("prolongErr:", err);
        switch (err.statusCode) {
          case 400:
            getNewItem();
            break;
          default:
            break;
        }
      });

    closePopup("leaveSession");
  }

  const handleOnIdle = () => {
    openPopup("leaveSession");
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  const [submitError, setSubmitError] = useState("");
  function handleProductSubmit(values, resetForm) {
    if (!isSubmitPreloaderVisible && !submitError) {
      setSubmitPreloaderVisible(true)
      let data = new FormData();
      if (!values.noPhoto) {
        values.photo.forEach((photo) => data.append("photos", photo));
      }

      data.append(
        "data",
        JSON.stringify({
          _id: item._id,
          category_id: selectedCategory._id,
          sub_category_id: selectedSubCategory._id,
          room: values.room,
          description: !values.noDescription ? values.description : null,
          link: values.source,
          features: {
            height: !values.noHeight ? Number(values.height) : null,
            width: !values.noWidth ? Number(values.width) : null,
            depth: !values.noDepth ? Number(values.depth) : null,
            weight: !values.noWeight ? Number(values.weight) : null,
            colour: !values.noColor ? values.color : null,
            material: !values.noMaterial ? values.material : null,
          },
          additional_features: values.extraCharacteristics,
        })
      );

      mainApi
        .createItem({ data })
        .then((res) => {
          console.log("createItem:", res);
          resetForm();
          setEmptyBlocks(Array(9).fill(" "));
          getNewItem();
          window.scrollTo({ top: 0, behavior: "smooth" });
          setUser((prewItem) => ({
            ...prewItem,
            done: (Number(prewItem.done) + 1).toString(),
          }));
        })
        .catch((err) => {
          if (err.detail) {
            setSubmitError(err.detail);
          } else {
            setSubmitError("Something went wrong");
          }
          console.log("createErr:", err);
        })
        .finally(() => {
          setSubmitPreloaderVisible(false)
          setTimeout(() => {
            setSubmitError("");

          }, 5000);
        });
    }

  }

  function handleNewCategorySubmit(values) {
    const category =
      values.newCategory.slice(0, 1).toUpperCase() +
      values.newCategory.slice(1).toLowerCase();

    mainApi
      .addNewCategory({ newCategory: category })
      .then((res) => {
        console.log("newCategory:", res);
        setCategories((prewArray) => [...prewArray, res]);
      })
      .catch((err) => console.log("newCategoryErr:", err));
  }


  function handleNewSubcategorySubmit(values) {
    console.log(values, item, selectedCategory);

    const subcategory =
      values.newSubcategory.slice(0, 1).toUpperCase() +
      values.newSubcategory.slice(1).toLowerCase();

    mainApi
      .addNewSubcategory({
        category: selectedCategory._id,
        newSubcategory: subcategory,
      })
      .then((res) => {
        console.log("newSubcategory:", res);
        setSubcategories((prewArray) => [...prewArray, res]);
      })
      .catch((err) => console.log("newSubcategoryErr:", err));
  }

  function handleSkipItemSubmit(values) {
    const reason = {
      not_found: values.reason === NOT_FOUND_REASON,
      wrong_name_or_production: values.reason === WRONG_NAME_REASON,
      skipped: false,
      other_reason: Boolean(values.anotherReason) ? values.anotherReason : null,
    };

    skipItem(reason);
  }

  function handleRewiewSubmit(values) {
    console.log(values);
  }

  function handleReportSubmit(values) {
    console.log(values);
  }

  function handleSkipReviewItemSubmit(values) {
    console.log(values);
  }

  function handleSelectCategory(item, setFieldValue) {

    setSelectedCategory(item);
    setFieldValue('subcategory', '')
    setFieldValue('room', '')
  }

  function handleSelectSubCategory(item, setFieldValue) {
    setSelectedSubCategory(item)
    setFieldValue('room', '')
  }

  const [pasteError, setPasteError] = useState('')
  // const [pasteText, setPasteText] = useState('')
  function handlePasteFromClipboard({ values, setFieldValue, setTouched, touched, errors }) {
    if (!pasteError) {

      navigator.clipboard.readText()
        .then(text => {
          const formated_text = text.trim()
            .replace(/\?/g, '')


          setFieldValue('pasteText', text.trim())
          // setTimeout(() => {
          //   setPasteText('')
          // }, 5000);
          let array = formated_text
            .replace(/\s—\s/g, "$")
            .replace(/\t/g, "$")
            .split(/\r?\n/).filter((item) => {
              if (item.length > 0) return true
              else return false
            }).filter((item) => {
              if (item.length > 0 && item.indexOf('$') > -1) return true
              else return false
            })

          if (array.length === 0 || array.filter((item) => {
            if (item.length > 0 && item.indexOf('$') > -1) return true
            else return false
          }).filter((item) => {
            if (item.length > 0) return true
            else return false
          }).length === 0) {

            array = formated_text
              .replace(/:/g, "$")
              .replace(/\s—\s/g, "$")
              .replace(/\t/g, "$")
              .split(/\r?\n/).filter((item) => {
                if (item.length > 0) return true
                else return false
              }).filter((item) => {
                if (item.length > 0 && item.indexOf('$') > -1) return true
                else return false
              })
          }



          let resultTouched = {
            ...touched,
          }
          let extrasArray = []
          if (array.length > 0 && array.filter((item) => {
            if (item.length > 0 && item.indexOf('$') > -1) return true
            else return false
          }).filter((item) => {
            if (item.length > 0) return true
            else return false
          }).length > 0) {
            array.forEach((item) => {
              let formatedItemArray = item.trim().split('$')
              let keyValue = formatedItemArray[0].trim().toLowerCase().replace(/:/g, "")
              let valueValue = formatedItemArray[1].trim().replace(/:/g, "")
              if (isNaN(valueValue) && valueValue.length > 0) {
                valueValue = valueValue.substring(0, 1).toUpperCase() + valueValue.substring(1)
              }

              if (EXEPTIONS_ARRAY.indexOf(keyValue) === -1 && keyValue.length > 0 && valueValue.length > 0) {

                switch (keyValue) {
                  case 'высота':
                  case 'высота мм':
                  case 'высота, мм':
                  case 'высота, мм.':
                  case 'высота мм.':
                  case 'высота (мм)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("height", valueValue)
                      setFieldValue("noHeight", false)
                      resultTouched = {
                        ...resultTouched,
                        height: true,
                      }
                    } else {
                      if (valueValue.indexOf('мм') > -1) {
                        let cropped = valueValue.replace(/мм*./gm, '')
                        setFieldValue("height", cropped)
                        setFieldValue("noHeight", false)
                        resultTouched = {
                          ...resultTouched,
                          height: true,
                        }
                      } else if (valueValue.indexOf('см') > -1) {
                        let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                        setFieldValue("height", cropped)
                        setFieldValue("noHeight", false)
                        resultTouched = {
                          ...resultTouched,
                          height: true,
                        }
                      }
                    }


                    break;
                  case 'высота см':
                  case 'высота, см':
                  case 'высота, см.':
                  case 'высота см.':
                  case 'высота (см)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("height", Number(valueValue) * 10)
                      setFieldValue("noHeight", false)
                      resultTouched = {
                        ...resultTouched,
                        height: true,
                      }
                    }

                    break;


                  case 'ширина':
                  case 'ширина мм':
                  case 'ширина, мм':
                  case 'ширина, мм.':
                  case 'ширина мм.':
                  case 'ширина (мм)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("width", valueValue)
                      setFieldValue("noWidth", false)

                      resultTouched = {
                        ...resultTouched,
                        width: true,
                      }
                    } else {

                      if (valueValue.indexOf('мм') > -1) {
                        let cropped = valueValue.replace(/мм*./gm, '')
                        setFieldValue("width", cropped)
                        setFieldValue("noWidth", false)
                        resultTouched = {
                          ...resultTouched,
                          width: true,
                        }
                      } else if (valueValue.indexOf('см') > -1) {
                        let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                        setFieldValue("width", cropped)
                        setFieldValue("noWidth", false)
                        resultTouched = {
                          ...resultTouched,
                          width: true,
                        }
                      }
                    }
                    break;
                  case 'ширина см':
                  case 'ширина, см':
                  case 'ширина, см.':
                  case 'ширина см.':
                  case 'ширина (см)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("width", Number(valueValue) * 10)
                      setFieldValue("noWidth", false)

                      resultTouched = {
                        ...resultTouched,
                        width: true,
                      }
                    }
                    break;

                  case 'глубина':
                  case 'глубина мм':
                  case 'глубина, мм':
                  case 'глубина, мм.':
                  case 'глубина мм.':
                  case 'длина':
                  case 'длина мм':
                  case 'длина, мм':
                  case 'длина, мм.':
                  case 'длина мм.':
                  case 'ширина/длина':
                  case 'длина (мм)':
                  case 'глубина (мм)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("depth", valueValue)
                      setFieldValue("noDepth", false)

                      resultTouched = {
                        ...resultTouched,
                        depth: true,
                      }
                    } else {
                      if (valueValue.indexOf('мм') > -1) {
                        let cropped = valueValue.replace(/мм*./gm, '')
                        setFieldValue("depth", cropped)
                        setFieldValue("noDepth", false)
                        resultTouched = {
                          ...resultTouched,
                          depth: true,
                        }
                      } else if (valueValue.indexOf('см') > -1) {
                        let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                        setFieldValue("depth", cropped)
                        setFieldValue("noDepth", false)
                        resultTouched = {
                          ...resultTouched,
                          depth: true,
                        }
                      }
                    }
                    break;
                  case 'глубина см':
                  case 'глубина, см':
                  case 'глубина, см.':
                  case 'глубина см.':
                  case 'длина см':
                  case 'длина, см':
                  case 'длина, см.':
                  case 'длина см.':
                  case 'длина (см)':
                  case 'глубина (см)':
                    if (!isNaN(valueValue)) {
                      setFieldValue("depth", Number(valueValue) * 10)
                      setFieldValue("noDepth", false)

                      resultTouched = {
                        ...resultTouched,
                        depth: true,
                      }
                    }
                    break;

                  case 'вес':
                  case 'вес кг':
                  case 'вес, кг':
                  case 'вес, кг.':
                  case 'вес кг.':
                  case 'вес упаковки':
                    if (!isNaN(valueValue)) {
                      setFieldValue("weight", valueValue)
                      setFieldValue("noWeight", false)

                      resultTouched = {
                        ...resultTouched,
                        weight: true,
                      }
                    } else {
                      if (valueValue.indexOf('кг') > -1) {
                        let cropped = valueValue.replace(/кг*./gm, '')
                        setFieldValue("weight", cropped)
                        setFieldValue("noWeight", false)
                        resultTouched = {
                          ...resultTouched,
                          weight: true,
                        }
                      }
                    }
                    break;

                  case 'цвет':
                  case 'цветовое исполнение':
                    setFieldValue("color", valueValue)
                    setFieldValue("noColor", false)
                    resultTouched = {
                      ...resultTouched,
                      color: true,
                    }
                    break;

                  case 'материал':
                    setFieldValue("material", valueValue)
                    setFieldValue("noMaterial", false)
                    resultTouched = {
                      ...resultTouched,
                      material: true,
                    }
                    break;

                  default:
                    extrasArray = [...extrasArray, {
                      key: keyValue.substring(0, 1).toUpperCase() + keyValue.substring(1),
                      value: valueValue
                    }]

                    break;
                }

              }
              // result = {
              //   ...result,
              //   [keyValue]: valueValue
              // }


            })
            if (extrasArray.length > 0) {
              setFieldValue('extraCharacteristics', extrasArray)
            }

            setTimeout(() => {
              setTouched(resultTouched)
            }, 500);

          } else {

            setPasteError('Формат данных не подходит для автоматической вставки')
            setTimeout(() => {
              setPasteError('')
            }, 4000);
          }

        })
        .catch(err => {
          console.error('Failed to read clipboard contents: ', err);
          setPasteError('Не удалось прочитать содержимое буфера обмена: он либо пуст, либо вы запретили доступ')
          setTimeout(() => {
            setPasteError('')
          }, 4000);
        });
    }

  }


  function handlePasteChange({ e, values, setFieldValue, setTouched, touched, errors }) {

    setPasteError('')
    const text = e.currentTarget.value
    setFieldValue('pasteText', text)
    if (!text) {
      setPasteError('')
    } else {
      let array = text.trim()
        .replace(/\s—\s/g, "$")
        .replace(/\t/g, "$")
        .split(/\r?\n/).filter((item) => {
          if (item.length > 0) return true
          else return false
        }).filter((item) => {
          if (item.length > 0 && item.indexOf('$') > -1) return true
          else return false
        })

      if (array.length === 0 || array.filter((item) => {
        if (item.length > 0 && item.indexOf('$') > -1) return true
        else return false
      }).filter((item) => {
        if (item.length > 0) return true
        else return false
      }).length === 0) {

        array = text.trim()
          .replace(/:/g, "$")
          .replace(/\s—\s/g, "$")
          .replace(/\t/g, "$")
          .split(/\r?\n/).filter((item) => {
            if (item.length > 0) return true
            else return false
          }).filter((item) => {
            if (item.length > 0 && item.indexOf('$') > -1) return true
            else return false
          })
      }



      let resultTouched = {
        ...touched,
      }
      let extrasArray = []
      if (array.length > 0 && array.filter((item) => {
        if (item.length > 0 && item.indexOf('$') > -1) return true
        else return false
      }).filter((item) => {
        if (item.length > 0) return true
        else return false
      }).length > 0) {
        array.forEach((item) => {
          let formatedItemArray = item.trim().split('$')
          let keyValue = formatedItemArray[0].trim().toLowerCase().replace(/:/g, "")
          let valueValue = formatedItemArray[1].trim().replace(/:/g, "")
          if (isNaN(valueValue) && valueValue.length > 0) {
            valueValue = valueValue.substring(0, 1).toUpperCase() + valueValue.substring(1)
          }

          if (EXEPTIONS_ARRAY.indexOf(keyValue) === -1 && keyValue.length > 0 && valueValue.length > 0) {

            switch (keyValue) {
              case 'высота':
              case 'высота мм':
              case 'высота, мм':
              case 'высота, мм.':
              case 'высота мм.':
              case 'высота (мм)':
                if (!isNaN(valueValue)) {
                  setFieldValue("height", valueValue)
                  setFieldValue("noHeight", false)
                  resultTouched = {
                    ...resultTouched,
                    height: true,
                  }
                } else {
                  if (valueValue.indexOf('мм') > -1) {
                    let cropped = valueValue.replace(/мм*./gm, '')
                    setFieldValue("height", cropped)
                    setFieldValue("noHeight", false)
                    resultTouched = {
                      ...resultTouched,
                      height: true,
                    }
                  } else if (valueValue.indexOf('см') > -1) {
                    let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                    setFieldValue("height", cropped)
                    setFieldValue("noHeight", false)
                    resultTouched = {
                      ...resultTouched,
                      height: true,
                    }
                  }
                }


                break;
              case 'высота см':
              case 'высота, см':
              case 'высота, см.':
              case 'высота см.':
              case 'высота (см)':
                if (!isNaN(valueValue)) {
                  setFieldValue("height", Number(valueValue) * 10)
                  setFieldValue("noHeight", false)
                  resultTouched = {
                    ...resultTouched,
                    height: true,
                  }
                }

                break;


              case 'ширина':
              case 'ширина мм':
              case 'ширина, мм':
              case 'ширина, мм.':
              case 'ширина мм.':
              case 'ширина (мм)':
                if (!isNaN(valueValue)) {
                  setFieldValue("width", valueValue)
                  setFieldValue("noWidth", false)

                  resultTouched = {
                    ...resultTouched,
                    width: true,
                  }
                } else {

                  if (valueValue.indexOf('мм') > -1) {
                    let cropped = valueValue.replace(/мм*./gm, '')
                    setFieldValue("width", cropped)
                    setFieldValue("noWidth", false)
                    resultTouched = {
                      ...resultTouched,
                      width: true,
                    }
                  } else if (valueValue.indexOf('см') > -1) {
                    let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                    setFieldValue("width", cropped)
                    setFieldValue("noWidth", false)
                    resultTouched = {
                      ...resultTouched,
                      width: true,
                    }
                  }
                }
                break;
              case 'ширина см':
              case 'ширина, см':
              case 'ширина, см.':
              case 'ширина см.':
              case 'ширина (см)':
                if (!isNaN(valueValue)) {
                  setFieldValue("width", Number(valueValue) * 10)
                  setFieldValue("noWidth", false)

                  resultTouched = {
                    ...resultTouched,
                    width: true,
                  }
                }
                break;

              case 'глубина':
              case 'глубина мм':
              case 'глубина, мм':
              case 'глубина, мм.':
              case 'глубина мм.':
              case 'длина':
              case 'длина мм':
              case 'длина, мм':
              case 'длина, мм.':
              case 'длина мм.':
              case 'ширина/длина':
              case 'длина (мм)':
              case 'глубина (мм)':
                if (!isNaN(valueValue)) {
                  setFieldValue("depth", valueValue)
                  setFieldValue("noDepth", false)

                  resultTouched = {
                    ...resultTouched,
                    depth: true,
                  }
                } else {
                  if (valueValue.indexOf('мм') > -1) {
                    let cropped = valueValue.replace(/мм*./gm, '')
                    setFieldValue("depth", cropped)
                    setFieldValue("noDepth", false)
                    resultTouched = {
                      ...resultTouched,
                      depth: true,
                    }
                  } else if (valueValue.indexOf('см') > -1) {
                    let cropped = Number(valueValue.replace(/см*./gm, '')) * 10
                    setFieldValue("depth", cropped)
                    setFieldValue("noDepth", false)
                    resultTouched = {
                      ...resultTouched,
                      depth: true,
                    }
                  }
                }
                break;
              case 'глубина см':
              case 'глубина, см':
              case 'глубина, см.':
              case 'глубина см.':
              case 'длина см':
              case 'длина, см':
              case 'длина, см.':
              case 'длина см.':
              case 'длина (см)':
              case 'глубина (см)':
                if (!isNaN(valueValue)) {
                  setFieldValue("depth", Number(valueValue) * 10)
                  setFieldValue("noDepth", false)

                  resultTouched = {
                    ...resultTouched,
                    depth: true,
                  }
                }
                break;

              case 'вес':
              case 'вес кг':
              case 'вес, кг':
              case 'вес, кг.':
              case 'вес кг.':
              case 'вес упаковки':
                if (!isNaN(valueValue)) {
                  setFieldValue("weight", valueValue)
                  setFieldValue("noWeight", false)

                  resultTouched = {
                    ...resultTouched,
                    weight: true,
                  }
                } else {
                  if (valueValue.indexOf('кг') > -1) {
                    let cropped = valueValue.replace(/кг*./gm, '')
                    setFieldValue("weight", cropped)
                    setFieldValue("noWeight", false)
                    resultTouched = {
                      ...resultTouched,
                      weight: true,
                    }
                  }
                }
                break;

              case 'цвет':
              case 'цветовое исполнение':
                setFieldValue("color", valueValue)
                setFieldValue("noColor", false)
                resultTouched = {
                  ...resultTouched,
                  color: true,
                }
                break;

              case 'материал':
                setFieldValue("material", valueValue)
                setFieldValue("noMaterial", false)
                resultTouched = {
                  ...resultTouched,
                  material: true,
                }
                break;

              default:
                extrasArray = [...extrasArray, {
                  key: keyValue.substring(0, 1).toUpperCase() + keyValue.substring(1),
                  value: valueValue
                }]

                break;
            }

          }
          // result = {
          //   ...result,
          //   [keyValue]: valueValue
          // }


        })
        if (extrasArray.length > 0) {
          setFieldValue('extraCharacteristics', extrasArray)
        }

        setTimeout(() => {
          setTouched(resultTouched)
        }, 500);

      } else {

        setPasteError('Формат данных не подходит для автоматической вставки')

      }

    }


  }

  useEffect(() => {
    console.log({ defaultSizes })
  }, [defaultSizes])
  return (
    <main className="main">
      <h2 className="main__title">
        {user.type === "reviewer" ? "Проверьте" : "Найдите"} фото и описание
        этого товара
      </h2>
      <p className="main__product">{item.name ? item.name : 'Нет названия'}</p>
      <a className="main__product-search-link" target="_blank" rel="noreferrer" href={`https://www.google.com/search?${new URLSearchParams({ q: item.name })}`}>Поиск товара в Google</a>
      <a className="main__product-search-link" target="_blank" rel="noreferrer" href={`https://yandex.ru/search/?${new URLSearchParams({ text: item.name })}`}>Поиск товара в Яндекс</a>
      {item.firstc_data ? (
        <p className="main__producer">
          {item.firstc_data.brand_name
            ? `Производитель: ${item.firstc_data.brand_name}`
            : 'Производитель: Отсутствует'}
        </p>
      ) : null}
      {item.firstc_data ? (
        <p className="main__producer">
          {item.firstc_data.manufacturer_name
            ? `Поставщик: ${item.firstc_data.manufacturer_name}`
            : 'Поставщик: Отсутствует'}
        </p>
      ) : null}

      <Formik
        initialValues={
          user.type === "reviewer" ? initialValuesReviewer :
            initialValuesWriter
        }
        validationSchema={
          user.type === "reviewer" ? ReviewSchema : ProductSchema
        }
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          user.type === "reviewer"
            ? handleRewiewSubmit(values)
            : handleProductSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setTouched,
          isValid,
          dirty,
        }) => (
          <Form className="main__form" encType="multipart/form-data">
            <div className="main__form-box">
              <div className="main__category-block">
                <div className="main__select-box">
                  <SelectField
                    userType={user.type}
                    name="category"
                    label="Категория"
                    value={
                      user.type === "reviewer" ? item.category : values.category
                    }
                    placeholder="Выберите категорию"
                    touched={touched.category}
                    error={errors.category}
                    list={categories}
                    {...{ setFieldValue, getSubcategories }}
                    handleSelectCategory={handleSelectCategory}
                  />
                  {user.type === "writer" ? (
                    <button
                      className="main__category-button"
                      type="button"
                      onClick={() => openPopup("newCategory")}
                    >
                      Моей категории нет в списке
                    </button>
                  ) : user.type === "reviewer" ? (
                    <>
                      <Checkbox
                        name="categoryCorrect"
                        label="Всё правильно"
                        isChecked={values.categoryCorrect}
                        disabled={values.categoryUncorrect}
                        {...{ setFieldValue }}
                      />
                      <Checkbox
                        name="categoryUncorrect"
                        label="Не верная информация"
                        isChecked={values.categoryUncorrect}
                        disabled={values.categoryCorrect}
                        type="wrong"
                        {...{ setFieldValue }}
                      />
                    </>
                  ) : null}
                </div>

                {user.type === "reviewer" || Boolean(values.category) ? (
                  <div className="main__select-box">
                    <SelectField
                      userType={user.type}
                      name="subcategory"
                      label="Суб-категория"
                      value={
                        user.type === "reviewer"
                          ? item.subcategory
                          : values.subcategory
                      }
                      placeholder="Выберите суб-категорию"
                      touched={touched.subcategory}
                      error={errors.subcategory}
                      list={subcategories}
                      {...{ setFieldValue }}
                      handleSelectCategory={handleSelectSubCategory}
                    />
                    {user.type === "writer" ? (
                      <button
                        className="main__category-button"
                        type="button"
                        onClick={() => openPopup("newSubcategory")}
                      >
                        Моей суб-категории нет в списке
                      </button>
                    ) : user.type === "reviewer" ? (
                      <>
                        <Checkbox
                          name="subcategoryCorrect"
                          label="Всё правильно"
                          isChecked={values.subcategoryCorrect}
                          disabled={values.subcategoryUncorrect}
                          {...{ setFieldValue }}
                        />
                        <Checkbox
                          name="subcategoryUncorrect"
                          label="Не верная информация"
                          isChecked={values.subcategoryUncorrect}
                          disabled={values.subcategoryCorrect}
                          type="wrong"
                          {...{ setFieldValue }}
                        />
                      </>
                    ) : null}
                  </div>
                ) : null}

                {user.type === "reviewer" || Boolean(values.subcategory) ? (
                  <div className="main__select-box">
                    <SelectField
                      userType={user.type}
                      name="room"
                      label="Комната"
                      value={user.type === "reviewer" ? item.room : values.room}
                      placeholder="Выберите комнату"
                      touched={touched.room}
                      error={errors.room}
                      list={ROOM_LIST}
                      {...{ setFieldValue }}
                    />
                    {user.type === "reviewer" ? (
                      <>
                        <Checkbox
                          name="roomCorrect"
                          label="Всё правильно"
                          isChecked={values.roomCorrect}
                          disabled={values.roomUncorrect}
                          {...{ setFieldValue }}
                        />
                        <Checkbox
                          name="roomUncorrect"
                          label="Не верная информация"
                          isChecked={values.roomUncorrect}
                          disabled={values.roomCorrect}
                          type="wrong"
                          {...{ setFieldValue }}
                        />
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className="main__description-block">
                <div className="main__photo-box">
                  <p
                    className={`main__label ${user.type === "writer" ? "main__label_type_writer" : ""
                      }`}
                  >
                    Фото товара
                  </p>
                  <div
                    className={`main__photo-grid-box ${touched.photo && Boolean(errors.photo)
                      ? "main__photo-grid-box_error"
                      : ""
                      } ${touched.photo &&
                        !Boolean(errors.photo) &&
                        values.photo.length > 0
                        ? "main__photo-grid-box_valid"
                        : ""
                      } ${values.noPhoto ? "main__photo-grid-box_disabled" : ""
                      } ${user.type === "reviewer"
                        ? "main__photo-grid-box_type_reviewer"
                        : ""
                      }`}
                  >
                    <div className="main__photo-grid">
                      {user.type === "writer" &&
                        values.photoURL &&
                        values.photoURL.length > 0
                        ? values.photoURL.map((image, i) => (
                          <div className="main__photo-item" key={`main__photo-item_` + i}>
                            <img
                              className="main__photo"
                              src={image}
                              alt={item.product}
                            />
                            <button
                              className="main__remove-photo-button"
                              type="button"
                              aria-label="Удалить фотографию"
                              onClick={() =>
                                handleRemovePhoto(i, setFieldValue, values)
                              }
                            >
                              {REMOVE_BUTTON_ICON}
                            </button>
                          </div>
                        ))
                        : user.type === "reviewer" &&
                          item.photoURL &&
                          item.photoURL.length > 0
                          ? item.photoURL.map((image, i) => (
                            <img
                              className="main__photo"
                              src={image}
                              alt={item.product}
                              key={i}
                            />
                          ))
                          : null}
                      {user.type === "writer" &&
                        values.photo &&
                        values.photo.length < 10 ? (
                        <div className="main__input-image-box">
                          <label
                            className="main__input-image-label"
                            htmlFor="photo"
                          >
                            <input
                              className="main__input-image"
                              id="photo"
                              name="photo"
                              type="file"
                              accept="image/jpg, image/jpeg, image/webp"
                              multiple
                              onChange={(evt) =>
                                handlePhotoChange(evt, setFieldValue, values)
                              }
                              onBlur={() =>
                                setTouched({ ...touched, photo: true })
                              }
                            />
                            {ADD_ITEM_ICON}
                          </label>
                        </div>
                      ) : user.type === "reviewer" &&
                        item.photo &&
                        item.photo.length < 10 ? (
                        <div className="main__photo-container" />
                      ) : null}
                      {user.type === "writer" &&
                        values.photo &&
                        values.photo.length < 9
                        ? emptyBlocks.map((el, i) => (
                          <div
                            className="main__photo-container"
                            key={`Empty${i}`}
                          />
                        ))
                        : user.type === "reviewer" &&
                          item.photo &&
                          item.photo.length < 9
                          ? Array(10 - item.photo.length - 1)
                            .fill(" ")
                            .map((el, i) => (
                              <div
                                className="main__photo-container"
                                key={`Empty${i}`}
                              />
                            ))
                          : null}
                    </div>
                    <p className="main__photo-text">
                      К одному товару можно добавить максимум 10 фотографий.
                    </p>
                  </div>
                  {user.type === "writer" ? (
                    <Checkbox
                      name="noPhoto"
                      label="У моего товара нет фото"
                      isChecked={values.noPhoto}
                      {...{ setFieldValue }}
                    />
                  ) : user.type === "reviewer" ? (
                    <>
                      {item.noPhoto ? (
                        <p className="main__no-item-text">
                          У этого товара нет фото
                        </p>
                      ) : null}
                      <Checkbox
                        name="photoCorrect"
                        label="Всё правильно"
                        isChecked={values.photoCorrect}
                        disabled={values.photoUncorrect}
                        {...{ setFieldValue }}
                      />
                      <Checkbox
                        name="photoUncorrect"
                        label="Не верная информация"
                        isChecked={values.photoUncorrect}
                        disabled={values.photoCorrect}
                        type="wrong"
                        {...{ setFieldValue }}
                      />
                    </>
                  ) : null}
                  {user.type === "writer" &&
                    touched.photo &&
                    Boolean(errors.photo) ? (
                    <span className="main__error-message">{errors.photo}</span>
                  ) : null}
                </div>

                <div className="main__input-box">
                  <label
                    className={`main__label ${user.type === "writer" ? "main__label_type_writer" : ""
                      }`}
                    htmlFor="description"
                  >
                    Описание товара
                  </label>
                  <Field
                    as="textarea"
                    className={`main__input main__input_type_textarea ${touched.description && Boolean(errors.description)
                      ? "main__input_error"
                      : ""
                      } ${touched.description &&
                        !Boolean(errors.description) &&
                        Boolean(values.description)
                        ? "main__input_valid"
                        : ""
                      } ${values.noDescription ? "main__input_disabled" : ""} ${user.type === "reviewer"
                        ? "main__input_type_reviewer"
                        : ""
                      }`}
                    id="description"
                    name="description"
                    placeholder="Введите описание товара"
                    autoComplete="off"
                    readOnly={values.noDescription}
                    value={
                      user.type === "writer"
                        ? values.description
                        : item.description
                    }
                  />
                  {user.type === "writer" ? (
                    <Checkbox
                      name="noDescription"
                      label="У моего товара не указано описание"
                      isChecked={values.noDescription}
                      {...{ setFieldValue }}
                    />
                  ) : user.type === "reviewer" ? (
                    <>
                      {item.noDescription ? (
                        <p className="main__no-item-text">
                          У этого товара нет описания
                        </p>
                      ) : null}
                      <Checkbox
                        name="descriptionCorrect"
                        label="Всё правильно"
                        isChecked={values.descriptionCorrect}
                        disabled={values.descriptionUncorrect}
                        {...{ setFieldValue }}
                      />
                      <Checkbox
                        name="descriptionUncorrect"
                        label="Не верная информация"
                        isChecked={values.descriptionUncorrect}
                        disabled={values.descriptionCorrect}
                        type="wrong"
                        {...{ setFieldValue }}
                      />
                    </>
                  ) : null}
                  <ErrorMessage
                    component="span"
                    className="main__error-message"
                    name="description"
                  />
                </div>
              </div>

              <div className="main__source-block">
                <div className="main__input-box">
                  <label
                    className={`main__label ${user.type === "writer" ? "main__label_type_writer" : ""
                      }`}
                    htmlFor="source"
                  >
                    Ссылка на источник
                  </label>
                  <div
                    className={`main__source-input-box ${user.type === "writer"
                      ? "main__source-input-box_type_writer"
                      : ""
                      }`}
                  >
                    <Field
                      className={`main__input ${touched.source && Boolean(errors.source)
                        ? "main__input_error"
                        : ""
                        } ${touched.source && !Boolean(errors.source)
                          ? "main__input_valid"
                          : ""
                        } ${user.type === "reviewer"
                          ? "main__input_type_reviewer main__input_type_reviewer-source"
                          : ""
                        }`}
                      id="source"
                      name="source"
                      type="url"
                      placeholder="Введите ссылку"
                      autoComplete="off"
                      value={
                        user.type === "writer" ? values.source : item.source
                      }
                    />
                    <button
                      className={`main__copy-button ${user.type === "writer" ? "main__copy-button_hidden" : ""
                        }`}
                      type="button"
                      aria-label="Скопировать ссылку"
                      onClick={() => navigator.clipboard.writeText(item.source)}
                    >
                      {COPY_ICON}
                    </button>
                  </div>
                  <ErrorMessage
                    component="span"
                    className="main__error-message"
                    name="source"
                  />
                </div>
              </div>

              <div className="main__characteristic-block">
                <p
                  className={`main__label ${user.type === "writer" ? "main__label_type_writer" : ""
                    }`}
                >
                  Характеристики товара
                </p>
                <button type="button" className={`main__btn ${pasteError ? 'main__btn_error' : ''}`} onClick={() => {
                  handlePasteFromClipboard({ values, setFieldValue, setTouched, touched, errors })
                }}>
                  Вставить
                </button>
                <Field
                  as="textarea"
                  className={`main__input main__input_type_textarea main__input_type_textarea-paste`}
                  id="pasteText"
                  name="pasteText"
                  placeholder={`Ширина: 1000
Высота: 100`}
                  autoComplete="off"
                  required={false}
                  value={values.pasteText ? values.pasteText : defaultSizes && (defaultSizes.width || defaultSizes.depth) ? `Данные полученные из названия
${defaultSizes.width ? `Ширина, мм: ${defaultSizes.width}` : ''}
${defaultSizes.depth ? `Глубина, мм: ${defaultSizes.depth}` : ''}` : values.pasteText}
                  onChange={(e) => {
                    handlePasteChange({ e, values, setFieldValue, setTouched, touched, errors })
                  }}
                />
                <p className="main__paste-error">{pasteError}</p>
                <div className="main__charasteristic-list">
                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="height"
                    >
                      Высота, мм
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.height && Boolean(errors.height)
                          ? "main__input_error"
                          : ""
                          } ${touched.height &&
                            !Boolean(errors.height) &&
                            Boolean(values.height)
                            ? "main__input_valid"
                            : ""
                          } ${values.noHeight ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="height"
                        name="height"
                        type="tel"
                        placeholder="Введите высоту"
                        autoComplete="off"
                        readOnly={values.noHeight}
                        value={
                          user.type === "writer" ? values.height : item.height
                        }
                        onChange={(evt) => {
                          evt.preventDefault();
                          const { value } = evt.target;
                          if (!value || /\d/.test(value.slice(-1))) {
                            setFieldValue("height", value);
                          }
                        }}
                      />
                      {user.type === "writer" ? !values.height && (
                        <Checkbox
                          name="noHeight"
                          label="У моего товара не указана высота"
                          isChecked={values.noHeight}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noHeight ? (
                            <p className="main__no-item-text">
                              У этого товара не указана высота
                            </p>
                          ) : null}
                          <Checkbox
                            name="heightCorrect"
                            label="Всё правильно"
                            isChecked={values.heightCorrect}
                            disabled={values.heightUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="heightUncorrect"
                            label="Не верная информация"
                            isChecked={values.heightUncorrect}
                            disabled={values.heightCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="height"
                      />
                    </div>
                  </div>

                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="width"
                    >
                      Ширина, мм
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.width && Boolean(errors.width)
                          ? "main__input_error"
                          : ""
                          } ${touched.width &&
                            !Boolean(errors.width) &&
                            Boolean(values.width)
                            ? "main__input_valid"
                            : ""
                          } ${values.noWidth ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="width"
                        name="width"
                        type="tel"
                        placeholder="Введите ширину"
                        autoComplete="off"
                        readOnly={values.noWidth}
                        value={
                          user.type === "writer" ? values.width : item.width
                        }
                        onChange={(evt) => {
                          evt.preventDefault();
                          const { value } = evt.target;
                          if (!value || /\d/.test(value.slice(-1))) {
                            setFieldValue("width", value);
                          }
                        }}
                      />
                      {user.type === "writer" ? !values.width && (
                        <Checkbox
                          name="noWidth"
                          label="У моего товара не указана ширина"
                          isChecked={values.noWidth}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noWidth ? (
                            <p className="main__no-item-text">
                              У этого товара не указана ширина
                            </p>
                          ) : null}
                          <Checkbox
                            name="widthCorrect"
                            label="Всё правильно"
                            isChecked={values.widthCorrect}
                            disabled={values.widthUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="widthUncorrect"
                            label="Не верная информация"
                            isChecked={values.widthUncorrect}
                            disabled={values.widthCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="width"
                      />
                    </div>
                  </div>

                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="depth"
                    >
                      Глубина, мм
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.depth && Boolean(errors.depth)
                          ? "main__input_error"
                          : ""
                          } ${touched.depth &&
                            !Boolean(errors.depth) &&
                            Boolean(values.depth)
                            ? "main__input_valid"
                            : ""
                          } ${values.noDepth ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="depth"
                        name="depth"
                        type="tel"
                        placeholder="Введите глубину"
                        autoComplete="off"
                        readOnly={values.noDepth}
                        value={
                          user.type === "writer" ? values.depth : item.depth
                        }
                        onChange={(evt) => {
                          evt.preventDefault();
                          const { value } = evt.target;
                          if (!value || /\d/.test(value.slice(-1))) {
                            setFieldValue("depth", value);
                          }
                        }}
                      />
                      {user.type === "writer" ? !values.depth && (
                        <Checkbox
                          name="noDepth"
                          label="У моего товара не указана глубина"
                          isChecked={values.noDepth}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noDepth ? (
                            <p className="main__no-item-text">
                              У этого товара не указана глубина
                            </p>
                          ) : null}
                          <Checkbox
                            name="depthCorrect"
                            label="Всё правильно"
                            isChecked={values.depthCorrect}
                            disabled={values.depthUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="depthUncorrect"
                            label="Не верная информация"
                            isChecked={values.depthUncorrect}
                            disabled={values.depthCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="depth"
                      />
                    </div>
                  </div>

                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="weight"
                    >
                      Вес, кг
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.weight && Boolean(errors.weight)
                          ? "main__input_error"
                          : ""
                          } ${touched.weight &&
                            !Boolean(errors.weight) &&
                            Boolean(values.weight)
                            ? "main__input_valid"
                            : ""
                          } ${values.noWeight ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="weight"
                        name="weight"
                        type="tel"
                        placeholder="Введите вес"
                        autoComplete="off"
                        readOnly={values.noWeight}
                        value={
                          user.type === "writer" ? values.weight : item.weight
                        }
                        onChange={(evt) => {
                          evt.preventDefault();
                          const { value } = evt.target;
                          if (!value || /\d/.test(value.slice(-1))) {
                            setFieldValue("weight", value);
                          }
                        }}
                      />
                      {user.type === "writer" ? !values.weight && (
                        <Checkbox
                          name="noWeight"
                          label="У моего товара не указан вес"
                          isChecked={values.noWeight}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noWeight ? (
                            <p className="main__no-item-text">
                              У этого товара не указан вес
                            </p>
                          ) : null}
                          <Checkbox
                            name="weightCorrect"
                            label="Всё правильно"
                            isChecked={values.weightCorrect}
                            disabled={values.weightUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="weightUncorrect"
                            label="Не верная информация"
                            isChecked={values.weightUncorrect}
                            disabled={values.weightCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="weight"
                      />
                    </div>
                  </div>

                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="color"
                    >
                      Цвет
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.color && Boolean(errors.color)
                          ? "main__input_error"
                          : ""
                          } ${touched.color &&
                            !Boolean(errors.color) &&
                            Boolean(values.color)
                            ? "main__input_valid"
                            : ""
                          } ${values.noColor ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="color"
                        name="color"
                        type="text"
                        placeholder="Введите цвет"
                        autoComplete="off"
                        readOnly={values.noColor}
                        value={
                          user.type === "writer" ? values.color : item.color
                        }
                      />
                      {user.type === "writer" ? !values.color && (
                        <Checkbox
                          name="noColor"
                          label="У моего товара не указан цвет"
                          isChecked={values.noColor}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noColor ? (
                            <p className="main__no-item-text">
                              У этого товара не указан цвет
                            </p>
                          ) : null}
                          <Checkbox
                            name="colorCorrect"
                            label="Всё правильно"
                            isChecked={values.colorCorrect}
                            disabled={values.colorUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="colorUncorrect"
                            label="Не верная информация"
                            isChecked={values.colorUncorrect}
                            disabled={values.colorCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="color"
                      />
                    </div>
                  </div>

                  <div className="main__characteristic-box">
                    <label
                      className="main__characteristic-label"
                      htmlFor="material"
                    >
                      Материал
                    </label>
                    <div className="main__input-box">
                      <Field
                        className={`main__input ${touched.material && Boolean(errors.material)
                          ? "main__input_error"
                          : ""
                          } ${touched.material &&
                            !Boolean(errors.material) &&
                            Boolean(values.material)
                            ? "main__input_valid"
                            : ""
                          } ${values.noMaterial ? "main__input_disabled" : ""} ${user.type === "reviewer"
                            ? "main__input_type_reviewer"
                            : ""
                          }`}
                        id="material"
                        name="material"
                        type="text"
                        placeholder="Введите материал"
                        autoComplete="off"
                        readOnly={values.noMaterial}
                        value={
                          user.type === "writer"
                            ? values.material
                            : item.material
                        }
                      />
                      {user.type === "writer" ? !values.material && (
                        <Checkbox
                          name="noMaterial"
                          label="У моего товара не указан материал"
                          isChecked={values.noMaterial}
                          type="characteristic"
                          {...{ setFieldValue }}
                        />
                      ) : user.type === "reviewer" ? (
                        <>
                          {item.noMaterial ? (
                            <p className="main__no-item-text">
                              У этого товара не указан материал
                            </p>
                          ) : null}
                          <Checkbox
                            name="materialCorrect"
                            label="Всё правильно"
                            isChecked={values.materialCorrect}
                            disabled={values.materialUncorrect}
                            {...{ setFieldValue }}
                          />
                          <Checkbox
                            name="materialUncorrect"
                            label="Не верная информация"
                            isChecked={values.materialUncorrect}
                            disabled={values.materialCorrect}
                            type="wrong"
                            {...{ setFieldValue }}
                          />
                        </>
                      ) : null}
                      <ErrorMessage
                        component="span"
                        className="main__error-message"
                        name="material"
                      />
                    </div>
                  </div>

                  <div className="main__extra-block">
                    <ul className="main__extra-list">
                      {user.type === "writer" &&
                        values.extraCharacteristics &&
                        values.extraCharacteristics.length > 0
                        ? values.extraCharacteristics.map((extra, i) => (
                          <li className="main__extra-item" key={`extra${i}`}>
                            <button
                              className="main__remove-extra-button"
                              type="button"
                              aria-label="Удалить характеристику"
                              onClick={() =>
                                handleRemoveExtra(i, setFieldValue, values)
                              }
                            >
                              {REMOVE_BUTTON_ICON}
                            </button>

                            <Field
                              className={`main__input ${Boolean(extra.name) ? "main__input_valid" : ""
                                }`}
                              id={`extraKey${i}`}
                              name={`extraKey${i}`}
                              type="text"
                              placeholder="Характеристика"
                              autoComplete="off"
                              value={extra.key}
                              onChange={(evt) => {
                                const newExtra =
                                  values.extraCharacteristics.slice();
                                newExtra[i].key = evt.target.value;
                                setFieldValue(
                                  "extraCharacteristics",
                                  newExtra
                                );
                              }}
                            />

                            <Field
                              className={`main__input ${Boolean(extra.value)
                                ? "main__input_valid"
                                : ""
                                }`}
                              id={`extraValue${i}`}
                              name={`extraValue${i}`}
                              type="text"
                              placeholder="Параметры"
                              autoComplete="off"
                              value={extra.value}
                              onChange={(evt) => {
                                const newExtra =
                                  values.extraCharacteristics.slice();
                                newExtra[i].value = evt.target.value;
                                setFieldValue(
                                  "extraCharacteristics",
                                  newExtra
                                );
                              }}
                            />
                          </li>
                        ))
                        : user.type === "reviewer" &&
                          item.extraCharacteristics &&
                          item.extraCharacteristics.length > 0
                          ? item.extraCharacteristics.map((extra, i) => (
                            <li className="main__extra-item" key={`extra${i}`}>
                              <Field
                                className={`main__input ${user.type === "reviewer"
                                  ? "main__input_type_reviewer"
                                  : ""
                                  }`}
                                id={`extraKey${i}`}
                                name={`extraKey${i}`}
                                type="text"
                                value={extra.key}
                                readOnly
                              />
                              <Field
                                className={`main__input ${user.type === "reviewer"
                                  ? "main__input_type_reviewer"
                                  : ""
                                  }`}
                                id={`extraValue${i}`}
                                name={`extraValue${i}`}
                                type="text"
                                value={extra.value}
                                readOnly
                              />
                            </li>
                          ))
                          : null}
                    </ul>

                    {user.type === "writer" ? (
                      <button
                        className={`main__add-extra-button ${values.extraCharacteristics &&
                          values.extraCharacteristics.length !== 0 &&
                          !Boolean(
                            values.extraCharacteristics[
                              values.extraCharacteristics.length - 1
                            ].key
                          )
                          ? "main__add-extra-button_disabled"
                          : ""
                          } ${values.extraCharacteristics.length !== 0 &&
                            !Boolean(
                              values.extraCharacteristics[
                                values.extraCharacteristics.length - 1
                              ].value
                            )
                            ? "main__add-extra-button_disabled"
                            : ""
                          }`}
                        type="button"
                        onClick={() => {
                          const newExtra = [
                            ...values.extraCharacteristics,
                            { key: "", value: "" },
                          ];
                          setFieldValue("extraCharacteristics", newExtra);
                        }}
                      >
                        <p className="main__add-extra-text">
                          Добавить характеристику
                        </p>
                        {ADD_ITEM_ICON}
                      </button>
                    ) : user.type === "reviewer" ? (
                      <div className="main__extra-checkbox">
                        {item.extraCharacteristics &&
                          item.extraCharacteristics.length === 0 ? (
                          <p className="main__no-item-text">
                            У этого товара нет дополнительных характеристик
                          </p>
                        ) : null}
                        <Checkbox
                          name="extraCharacteristicsCorrect"
                          label="Все доп. характеристики указаны правильно"
                          isChecked={values.extraCharacteristicsCorrect}
                          disabled={values.extraCharacteristicsUncorrect}
                          {...{ setFieldValue }}
                        />
                        <Checkbox
                          name="extraCharacteristicsUncorrect"
                          label="Не верная информация"
                          isChecked={values.extraCharacteristicsUncorrect}
                          disabled={values.extraCharacteristicsCorrect}
                          type="wrong"
                          {...{ setFieldValue }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {user.type === "writer" ? (
              <>
                <div className="main__buttons-box">
                  <button
                    className="main__button main__button_type_skip"
                    type="button"
                    onClick={() => openPopup("skipItem")}
                  >
                    Пропустить товар
                  </button>
                  <button
                    className={`main__button main__button_type_submit ${!(isValid && dirty)
                      ? "main__button_type_submit_disabled"
                      : ""
                      }`}
                    type="submit"
                  >
                    {isSubmitPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Отправить товар'}

                  </button>
                </div>
                <p className="main__submit-error">{submitError}</p>
              </>
            ) : user.type === "reviewer" ? (
              <div
                className={`main__buttons-box ${user.type === "reviewer"
                  ? "main__buttons-box_type_reviewer"
                  : ""
                  }`}
              >
                <button
                  className="main__button main__button_type_report"
                  type="button"
                  onClick={() => openPopup("report")}
                >
                  Репорт
                </button>
                <button
                  className="main__button main__button_type_skip"
                  type="button"
                  onClick={() => openPopup("skipReviewItem")}
                >
                  Пропустить проверку товара
                </button>
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Проверено
                </button>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>

      <Popup
        popup="newCategory"
        isOpen={isPopupOpen.newCategory}
        title="Оставить запрос на добавление новой категории"
        {...{ closePopup }}
      >
        <Formik
          initialValues={{ newCategory: "" }}
          validationSchema={Yup.object({
            newCategory: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            closePopup("newCategory");
            handleNewCategorySubmit(values);
            resetForm();
          }}
        >
          {({ values, errors, touched, isValid, dirty }) => (
            <Form className="popup__form">
              <div className="popup__form-container">
                <div className="main__input-box">
                  <label className="main__label" htmlFor="newCategory">
                    Категория
                  </label>
                  <Field
                    className={`main__input ${touched.newCategory &&
                      !Boolean(errors.newCategory) &&
                      Boolean(values.newCategory)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="newCategory"
                    name="newCategory"
                    type="text"
                    placeholder="Введите название новой категории"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="popup__buttons-box">
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Отправить запрос
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>

      <Popup
        popup="newSubcategory"
        isOpen={isPopupOpen.newSubcategory}
        title="Оставить запрос на добавление новой суб-категории"
        {...{ closePopup }}
      >
        <Formik
          initialValues={{ newSubcategory: "" }}
          validationSchema={Yup.object({
            // category: Yup.string().required(),
            newSubcategory: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            closePopup("newSubcategory");
            handleNewSubcategorySubmit(values);
            resetForm();
          }}
        >
          {({ values, errors, touched, isValid, dirty }) => (
            <Form className="popup__form">
              <div className="popup__form-container">
                {/* <div className="main__input-box">
                  <label className="main__label" htmlFor="category">
                    Категория
                  </label>
                  <Field
                    className={`main__input ${touched.category &&
                      !Boolean(errors.category) &&
                      Boolean(values.category)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="category"
                    name="category"
                    type="text"
                    placeholder="В какую категорию нужно внести изменения?"
                    autoComplete="off"
                  />
                </div> */}

                <div className="main__input-box">
                  <label className="main__label" htmlFor="newSubcategory">
                    Суб-категория
                  </label>
                  <Field
                    className={`main__input ${touched.newSubcategory &&
                      !Boolean(errors.newSubcategory) &&
                      Boolean(values.newSubcategory)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="newSubcategory"
                    name="newSubcategory"
                    type="text"
                    placeholder="Введите название новой суб-категории"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="popup__buttons-box">
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Отправить запрос

                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>

      <Popup
        popup="skipItem"
        isOpen={isPopupOpen.skipItem}
        title={`Почему вы хотите пропустить заполнение товара:`}
        itemName={`${item.name ? item.name : 'Нет названия'}`}
        {...{ closePopup }}
      >
        <Formik
          initialValues={{ reason: "", anotherReason: "" }}
          validationSchema={Yup.object({
            reason: Yup.string().when("newSubcategory", {
              is: "",
              then: Yup.string().required(),
            }),
            anotherReason: Yup.string(),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            closePopup("skipItem");
            handleSkipItemSubmit(values);
            resetForm();
          }}
        >
          {({ values, errors, touched, setFieldValue, isValid, dirty }) => (
            <Form className="popup__form">
              <div className="popup__form-container">
                <SelectField
                  userType={user.type}
                  name="reason"
                  value={values.reason}
                  placeholder="Выберите причину"
                  touched={touched.reason}
                  error={errors.reason}
                  list={REASONS_LIST}
                  {...{ setFieldValue }}
                />

                <div className="main__input-box">
                  <label className="main__label" htmlFor="anotherReason">
                    Другая причина
                  </label>
                  <Field
                    as="textarea"
                    className={`main__input main__input_type_textarea ${touched.anotherReason && Boolean(values.anotherReason)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="anotherReason"
                    name="anotherReason"
                    placeholder="Введите причину"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="popup__buttons-box">
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Отправить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>

      <Popup
        popup="leaveSession"
        isOpen={isPopupOpen.leaveSession}
        title={"Хотите ли вы продолжить сессию?"}
        {...{ closePopup }}
      >
        <p className="popup__subtitle">Вы были неактивны в течении 15мин</p>
        <div className="popup__buttons-box">
          <button
            className="main__button main__button_type_submit"
            type="button"
            onClick={handleContinue}
          >
            Продолжить
          </button>

          <button
            className="main__button main__button_type_skip"
            type="button"
            onClick={loadNewItem}
          >
            Перейти к другому товару
          </button>
        </div>
      </Popup>

      <Popup
        popup="report"
        isOpen={isPopupOpen.report}
        title="Оставить запрос на отправление репорта"
        {...{ closePopup }}
      >
        <Formik
          initialValues={{ reasonReport: "" }}
          validationSchema={Yup.object({
            reason: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            closePopup("report");
            handleReportSubmit(values);
          }}
        >
          {({ values, errors, touched, isValid, dirty }) => (
            <Form className="popup__form">
              <div className="popup__form-container">
                <div className="main__input-box">
                  <label className="main__label" htmlFor="reasonReport">
                    Причина репорта
                  </label>
                  <Field
                    as="textarea"
                    className={`main__input main__input_type_textarea ${touched.reasonReport &&
                      !Boolean(errors.reasonReport) &&
                      Boolean(values.reasonReport)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="reasonReport"
                    name="reasonReport"
                    placeholder="Введите причину репорта"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="popup__buttons-box">
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Отправить запрос
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>

      <Popup
        popup="skipReviewItem"
        isOpen={isPopupOpen.skipReviewItem}
        title={"Почему вы хотите пропустить проверку этого товара?"}
        {...{ closePopup }}
      >
        <Formik
          initialValues={{ reasonReview: "", anotherReasonReview: "" }}
          validationSchema={Yup.object({
            reasonReview: Yup.string().when("newSubcategory", {
              is: "",
              then: Yup.string().required(),
            }),
            anotherReasonReview: Yup.string(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            closePopup("skipReviewItem");
            handleSkipReviewItemSubmit(values);
          }}
        >
          {({ values, errors, touched, setFieldValue, isValid, dirty }) => (
            <Form className="popup__form">
              <div className="popup__form-container">
                <SelectField
                  userType={user.type}
                  name="reasonReview"
                  value={values.reasonReview}
                  placeholder="Выберите причину"
                  touched={touched.reasonReview}
                  error={errors.reasonReview}
                  list={REASONS_LIST}
                  {...{ setFieldValue }}
                />

                <div className="main__input-box">
                  <label className="main__label" htmlFor="anotherReasonReview">
                    Другая причина
                  </label>
                  <Field
                    as="textarea"
                    className={`main__input main__input_type_textarea ${touched.anotherReasonReview &&
                      Boolean(values.anotherReasonReviewn)
                      ? "main__input_valid"
                      : ""
                      }`}
                    id="anotherReasonReview"
                    name="anotherReasonReview"
                    placeholder="Введите причину"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="popup__buttons-box">
                <button
                  className={`main__button main__button_type_submit ${!(isValid && dirty)
                    ? "main__button_type_submit_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  Отправить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>
    </main>
  );
}

export default Main;
